<script setup lang="ts">
import {
  type DkimPublicKeyResource,
  type DkimSettingsResource, type DnsDelegationVerificationResource,
  type DomainResource, DomainType,
} from '@/types/types.gen';
import DomainsSettingsLayout from '@/Pages/Domains/DomainSettingsLayout.vue';
import { DomainSettingsTab } from '@/Pages/Domains/types/DomainSettings';
import EnableDkimSettings from '@/Pages/Domains/DkimSettings/Partials/EnableDkimSettings.vue';
import { Link, router, useForm } from '@inertiajs/vue3';
import Button from 'primevue/button';
import type { PaginatedResponse } from '@/types/http';
import DisableDkimSettings from '@/Pages/Domains/DkimSettings/Partials/DisableDkimSettings.vue';
import { useRoute } from 'ziggy-js';
import responseCallbacks from '@/Utils/ResponseCallbacks';
import DkimSettingsForm from '@/Pages/Domains/DkimSettings/Partials/DkimSettingsForm.vue';
import { computed, provide, ref } from 'vue';
import SaveAndExit from '@/Components/SaveAndExit.vue';
import LinkSetupInstructions from '@/Pages/Domains/Partials/LinkSetupInstructions.vue';
import DkimPublicKeyList from '@/Pages/Domains/DkimSettings/Partials/DkimPublicKeyList.vue';
import ConfirmDialog from 'primevue/confirmdialog';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import type { SaveOrExitAction } from '@/Pages/Domains/types';
import type { DomainScore } from '@/types/cyclops.types.gen';
import VerificationStatus from '@/Components/VerificationStatus.vue';

const { domain, dkimSettings } = defineProps<{
  domain: DomainResource;
  dkimSettings: DkimSettingsResource;
  dkimPublicKeys: PaginatedResponse<DkimPublicKeyResource>;
  cyclopsScore: DomainScore | null;
  verification?: DnsDelegationVerificationResource;
}>();

const form = useForm<DkimSettingsResource>(dkimSettings);

provide('form', form);

const submit = (action: SaveOrExitAction) => {
  form.put(
    useRoute()('ui.dkim-settings.update', {
      customer: domain.customer_uuid,
      domain: domain.uuid,
      action,
    }),
    responseCallbacks,
  );
};

const createDkimKeyRoute = computed(() =>
  useRoute()('ui.dkim-public-keys.create', {
    customer: domain.customer_uuid,
    domain: domain.uuid,
  }),
);

const toast = useToast();
const { translate } = useTranslate();
const confirm = useConfirm();
const isVisible = ref(false);
const ImportDkimDomainKeys = () => {
  confirm.require({
    message: translate('dkim_settings.import_modal.message'),
    header: translate('dkim_settings.import_modal.header', { domain: domain.domain_name }),
    acceptLabel: translate('dkim_settings.import_modal.action'),
    rejectProps: {
      label: translate('global.buttons.cancel'),
      text: true,
    },
    accept: () => {
      const route = useRoute();
      router.get(
        route('ui.dkim-public-keys.import', {
          customer: domain.customer_uuid,
          domain: domain.uuid,
        }), {}, {
          onFinish: () => {
            toast.add({
              detail: translate('dkim_settings.import_modal.complete.message'),
              life: 3000,
              severity: 'success',
            });
          },
        },
      );
    },
    onShow: () => {
      isVisible.value = true;
    },
    onHide: () => {
      isVisible.value = false;
    },
  });
};
</script>

<template>
  <ConfirmDialog/>
  <DomainsSettingsLayout
    :title="$t('dkim_settings.title')"
    :domain="domain"
    :tab="DomainSettingsTab.DKIM"
    :cyclops-score="cyclopsScore"
  >
    <div v-if="dkimSettings.is_delegation_enabled" class="my-5 flex flex-row justify-between">
        <VerificationStatus v-if="verification" :verification="verification"/>
        <LinkSetupInstructions :domain="domain" href="ui.dkim-settings.setup" />
    </div>

    <EnableDkimSettings
      v-if="!dkimSettings.is_delegation_enabled"
      class="mt-8"
      :domain="domain"
    />
    <div v-else>
      <div class="flex items-center gap-3 pb-5">
        <Link :href="createDkimKeyRoute">
          <Button
            size="small"
            severity="secondary"
            icon="pi pi-plus"
            :label="$t('dkim_settings.actions.add_new')"
          />
        </Link>
        <Button
          size="small"
          outlined
          severity="secondary"
          icon="pi pi-file-import"
          :label="$t('global.buttons.import')"
          v-if="domain.first_report_date !== null"
          @click="ImportDkimDomainKeys"
        />
        <Button
          size="small"
          outlined
          severity="secondary"
          icon="pi pi-file-import"
          :label="$t('global.buttons.import')"
          v-else
          v-tooltip.top="translate('dkim_settings.import_modal.disabled_tooltip')"
          disabled
        />
      </div>
      <DkimPublicKeyList :domain="domain" :dkim-public-keys="dkimPublicKeys" />
      <DkimSettingsForm />
    </div>

    <DisableDkimSettings
      class="mt-8"
      v-if="dkimSettings.is_delegation_enabled"
      :domain="domain"
    />
    <template #footer>
      <SaveAndExit
        :cancel-route="route('ui.domains.index', [domain.customer_uuid])"
        @submit="submit"
        :disabled="domain.type === DomainType.PARKED"
      />
    </template>
    <slot />
  </DomainsSettingsLayout>
</template>
