<script setup lang="ts">
import Button from 'primevue/button';
import type { SaveOrExitAction } from '@/Pages/Domains/types';
import { Link } from '@inertiajs/vue3';
import { UiRestriction } from '@/types/types.gen';
import { useFeatureRestriction } from '@/Utils/useFeatureRestriction';

const { disabled = false } = defineProps<{
  cancelRoute?: string;
  disabled?: boolean;
}>();

const emits = defineEmits<{
  (e: 'submit', action: SaveOrExitAction): void;
}>();

const canDeleteDomain = !useFeatureRestriction(UiRestriction.NO_DOMAIN_DELETE);

</script>
<template>
  <div class="mt-8 flex w-full items-center justify-between">
    <div>
      <Button
        v-if="canDeleteDomain"
        severity="secondary"
        text
        icon="pi pi-trash"
        :label="$t('global.buttons.delete')"
      />
    </div>
    <div class="flex gap-4">
      <Link v-if="cancelRoute" :href="cancelRoute">
        <Button
          text
          :label="$t('global.buttons.cancel')"
          severity="secondary"
        />
      </Link>

      <Button
        severity="secondary"
        :disabled="disabled"
        outlined
        :label="$t('global.buttons.save')"
        @click="emits('submit',  'save')"
        type="submit"
      />
      <Button
        severity="primary"
        :disabled="disabled"
        :label="$t('global.buttons.save_exit')"
        @click="emits('submit', 'save_exit')"
        type="submit"
      />
    </div>
  </div>
</template>
