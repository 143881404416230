<script setup lang="ts">
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { ref } from 'vue';
import { type DomainResource, DomainType } from '@/types/types.gen';
import updateDomain from '@/Pages/Domains/UpdateDomain.ts';

const { domain } = defineProps<{
  domain: DomainResource;
}>();

const isVisible = ref(true);

const setDomainAsParked = () => {
  updateDomain(domain, { type: DomainType.PARKED });
}
</script>

<template>
  <Dialog
    v-model:visible="isVisible"
    modal
    :header="$t('domains.park_modal.header')"
    class="w-2/6"
  >
    {{ $t('domains.park_modal.description') }}
    <template #footer>
      <div class="flex items-center justify-between gap-2">
        <Button
          :label="$t('global.buttons.cancel')"
          severity="secondary"
          @click="isVisible = false"
          text
        />

        <Button
          @click="setDomainAsParked"
          :label="$t('domains.park_modal.action')"
          severity="danger"
          icon="pi pi-pause-circle"
          icon-pos="right"
        />
      </div>
    </template>
  </Dialog>
</template>
