<script setup lang="ts">
import SelectButton from 'primevue/selectbutton';
import FormGroup from '@/Components/FormGroup.vue';
import type { InertiaForm } from '@inertiajs/vue3';
import type { DkimSettingsResource } from '@/types/types.gen';
import { inject } from 'vue';

const dkimSettingsForm = inject<InertiaForm<DkimSettingsResource>>('form')!;
</script>

<template>
  <div>
    <div
      class="pb-5 pt-8 text-xl font-bold"
      v-text="$t('dkim_settings.policy_settings.heading')"
    />
    <div class="flex flex-col gap-8">
      <FormGroup
        :label="$t('dkim_settings.policy_settings.fields.policy.input_label')"
        :error="dkimSettingsForm.errors.policy"
      >
        <template v-slot:element="slotProps">
          <SelectButton
            v-model="dkimSettingsForm.policy"
            v-bind="slotProps"
            :allow-empty="false"
            :options="[
              {
                label: $t('dkim_settings.policy_settings.fields.policy.options.all'),
                value: 'all'
              },
              {
                label: $t('dkim_settings.policy_settings.fields.policy.options.some'),
                value: 'some'
              }
            ]"
            optionLabel="label"
            optionValue="value"
          />
        </template>
      </FormGroup>
      <FormGroup
        :label="$t('dkim_settings.policy_settings.fields.testing.input_label')"
        :error="dkimSettingsForm.errors.is_policy_enabled"
      >
        <template v-slot:element="slotProps">
          <SelectButton
            v-model="dkimSettingsForm.is_policy_enabled"
            v-bind="slotProps"
            :options="[
              {
                label: $t('global.fields.enabled_disabled.options.enabled'),
                value: true
              },
              {
                label: $t('global.fields.enabled_disabled.options.disabled'),
                value: false
              }
            ]"
            optionLabel="label"
            optionValue="value"
          />
        </template>
      </FormGroup>
    </div>
  </div>
</template>
