<script setup lang="ts">
import type { DomainResource } from '@/types/types.gen';
import type { DateRange } from '@/Pages/Statistics/Sender/types';

import { reactive } from 'vue';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Column from 'primevue/column';
import DataTable, { type DataTablePageEvent } from 'primevue/datatable';
import DatePicker, { type DatePickerEmitsOptions } from 'primevue/datepicker';
import IconField from 'primevue/iconfield';
import InputText from 'primevue/inputtext';
import InputIcon from 'primevue/inputicon';
import { Link, router } from '@inertiajs/vue3';
import DomainAvatar from '@/Pages/Domains/Partials/DomainAvatar.vue';
import ProtectionScoreBadge from '@/Pages/Domains/Partials/ProtectionScoreBadge.vue';
import DomainStatusTag from '@/Pages/Domains/Partials/DomainStatusTag.vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import DmarcPolicyTag from '@/Pages/Domains/Partials/DmarcPolicyTag.vue';
import { route, useRoute } from 'ziggy-js';
import DomainTypeTag from '@/Pages/Domains/Partials/DomainTypeTag.vue';
import LogsDrawer from '@/Pages/Logs/LogsDrawer.vue';
import { useFormatters } from '@/Utils/Formatting';
import { format } from 'date-fns';
import NavCrumbs from '@/Components/NavCrumbs.vue';
import { debounce } from 'lodash';
import type { PaginatedResponse } from '@/types/http';

const { dateRange } = defineProps<{
  domains: PaginatedResponse<DomainResource>;
  dateRange: DateRange; }
>();

const state = reactive({
  dateRange: [new Date(`${(dateRange.start_date)}`), new Date(`${(dateRange.end_date)}`)],
  logs: {
    visible: false,
    domain_name: '',
    path: '',
  },
  searchTerm: '',
});

const { formatDate } = useFormatters();

const search = debounce(() => {
  router.visit(route('ui.admin.domains.index', {
    search: state.searchTerm,
  }), {
    preserveState: true,
  });
}, 300);

const onPage = (event: DataTablePageEvent) => {
  router.visit(route('ui.admin.domains.index', {
    page: event.page + 1,
    search: state.searchTerm,
    ...dateRange,
  }), {
    preserveState: true,
  });
}

const updateDateRange: DatePickerEmitsOptions['update:modelValue'] = (dateRange) => {
  // Will only have 2 dates after full range is selected
  if (!Array.isArray(dateRange) || !dateRange[0] || !dateRange[1]) {
    return;
  }

  router.visit(useRoute()('ui.admin.domains.index', {
    start_date: format(dateRange[0], 'yyyy-LL-dd'),
    end_date: format(dateRange[1], 'yyyy-LL-dd'),
  }));
};

const openLogsDrawer = (domain: DomainResource) => {
  state.logs = {
    visible: true,
    domain_name: domain.domain_name,
    path: route('ui.logs.domain', [
      domain.customer_uuid,
      domain.uuid,
    ]),
  };
}

</script>

<template>
  <AppLayout :title="$t('domains.listing.title')">
    <NavCrumbs :model="[{ label: 'Admin' }]" />
    <LogsDrawer
      @hide="state.logs.visible = false"
      v-if="state.logs.visible"
      :path="state.logs.path"
      :header="$t('logs.header', {
        type: state.logs.domain_name
      })"
    />
    <div class="flex flex-col gap-8">
      <Card class="w-full">
        <template #content>
          <DataTable
            :value="domains.data"
            data-key="uuid"
            paginator
            lazy
            :first="domains.meta.from"
            :totalRecords="domains.meta.total"
            @page="onPage($event)"
            paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
            :currentPageReportTemplate="'&nbsp ' + $t('global.pagination.showing') + ' {first} ' + $t('global.pagination.to') + ' {last} ' + $t('global.pagination.of') + ' {totalRecords}'"
            :rows="10"
          >
            <template #empty>{{ $t('tables.not_found') }}</template>
            <template #header>
              <div class="flex gap-4">
                <DatePicker
                  class="w-80"
                  :disabled="state.searchTerm.length > 0"
                  selectionMode="range"
                  showIcon
                  v-model="state.dateRange"
                  @update:modelValue="updateDateRange($event)"
                  iconDisplay="input"
                  :maxDate="new Date()"
                  hideOnRangeSelection
                  pt:inputIconContainer:class="!-mt-3"
                  dateFormat="yy-mm-dd"
                  :fluid=true
                />

                <div class="flex justify-between">
                  <IconField>
                    <InputIcon>
                      <i class="pi pi-search" />
                    </InputIcon>
                    <InputText
                      @keyup="search"
                      :placeholder="$t('tables.search')"
                      v-model="state.searchTerm"
                    />
                  </IconField>
                </div>
                <div class="flex flex-1 justify-end">
                  <Button
                    icon="pi pi-external-link"
                    as="a"
                    :href="route('ui.admin.domains.export')"
                    text
                    v-tooltip.left="$t('tables.export')"
                  />
                </div>
              </div>
            </template>
            <Column
              field="domain_name"
              :header="$t('domains.listing.table.header.name')"
              filterField="name"
              headerClass="w-2/6"
            >
              <template #body="{ data }: { data: DomainResource }">
                <div class="flex items-center">
                  <DomainAvatar :domain="data" />
                  <div class="ml-4">
                    <div class=" font-medium leading-6 text-color">
                      {{ data.domain_name }}
                    </div>
                    <Link class="text-primary-default text-sm flex items-center gap-1"
                      :href="route('ui.dashboard.show', data.customer_uuid)"
                    >
                        {{ data.customer?.name ?? ''}}
                        <i class="pi pi-sign-in"></i>
                    </Link>
                  </div>

                  <div
                    v-if="data.is_favorite"
                    class="pi pi-star text-info-default ml-4 text-lg"
                    v-tooltip.top="$t('domains.tags.primary.tooltip')"
                  />
                </div>
              </template>
            </Column>

            <Column :header="$t('tables.created_on')"  field="created_on">
              <template #body="{ data } : { data: DomainResource }">
                {{ formatDate(new Date(data.created_at)) }}
              </template>
            </Column>

            <Column :header="$t('domains.listing.table.header.score')" field="score">
              <template #body="{ data }">
                <ProtectionScoreBadge
                  :score="data.score"
                />
              </template>
            </Column>

            <Column :header="$t('domains.listing.table.header.status')">
              <template #body="{ data }">
                <DomainStatusTag
                  :domain="data"
                />
              </template>
            </Column>

            <Column
              :header="$t('domains.listing.table.header.policy')"
              field="policy"
            >
              <template #body="{ data }">
                <DmarcPolicyTag
                  :policy="data.policy"
                />
              </template>
            </Column>

            <Column :header="$t('domains.listing.table.header.type')"  field="type">
              <template #body="{ data }">
                <DomainTypeTag :domain="data" />
              </template>
            </Column>

            <Column>
              <template #body="{ data }">
                <Button
                  @click="openLogsDrawer(data)"
                  text
                  v-tooltip.left="$t('domains.actions.view_logs')"
                  icon="pi pi-history"
                />
              </template>
            </Column>
          </DataTable>
        </template>
      </Card>
    </div>

  </AppLayout>
</template>
