<script setup lang="ts">
import AppLayout from '@/Layouts/AppLayout.vue';
import Card from 'primevue/card';
import { type DomainResource } from '@/types/types.gen';
import { inject, onUnmounted, reactive, ref } from 'vue';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { DomainSettingsTab } from '@/Pages/Domains/types/DomainSettings';
import { type InertiaForm, Link } from '@inertiajs/vue3';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import Tabs from 'primevue/tabs';
import DomainSettingsHeader from '@/Pages/Domains/Partials/DomainSettingsHeader.vue';
import ScoreCard from '@/Pages/Domains/Score/ScoreCard.vue';
import type { DomainScore } from '@/types/cyclops.types.gen';
import { useRoute } from 'ziggy-js';
import NavCrumbs from '@/Components/NavCrumbs.vue';
import { router } from '@inertiajs/vue3';
import { useConfirm } from 'primevue/useconfirm';

const { domain, tab } = defineProps<{
  title: string;
  domain: DomainResource;
  tab: DomainSettingsTab;
  cyclopsScore: DomainScore | null;
}>();
const confirm = useConfirm();

const { translate } = useTranslate();

const items = ref([
  { label: translate('domains.listing.title'), route: useRoute()('ui.domains.index', domain.customer_uuid) },
  { label: translate('domains.settings.title') },
]);

const state = reactive({
  selectedTabs: tab,
});

const routeParams = {
  customer: domain.customer_uuid,
  domain: domain.uuid,
};

const form: InertiaForm<{ isDirty: boolean }> = inject('form')!;

const confirmUnsavedChanges = router.on('before', (event) => {
  if (event.detail.visit.method === 'get' && form.isDirty) {
    event.preventDefault();

    confirm.require({
      header: translate('global.form.dirty.header'),
      message: translate('global.form.dirty.message'),
      acceptLabel: translate('global.form.dirty.accept'),
      rejectProps: {
        label: translate('global.form.dirty.reject'),
        severity: 'secondary',
        text: true,
      },
      accept: () => {
        confirmUnsavedChanges();
        router.visit(event.detail.visit.url);
      },
      reject: () => {
        state.selectedTabs = tab;
      },
    });
  }
});
onUnmounted(() => confirmUnsavedChanges());

</script>

<template>
  <AppLayout :title="title">
    <NavCrumbs :model="items" />
    <div class="flex flex-col xl:flex-row gap-8">
      <Card class="w-full">
        <template #content>
          <DomainSettingsHeader :domain="domain" />
          <Tabs v-model:value="state.selectedTabs">
            <TabList>
              <Link :href="route('ui.dmarc-settings.edit', routeParams)">
                <Tab :value="DomainSettingsTab.DMARC">DMARC</Tab>
              </Link>
              <Link :href="route('ui.spf-settings.edit', routeParams)">
                <Tab :value="DomainSettingsTab.SPF">SPF</Tab>
              </Link>
              <Link :href="route('ui.dkim-settings.edit', routeParams)">
                <Tab :value="DomainSettingsTab.DKIM">DKIM</Tab>
              </Link>
              <Link :href="route('ui.bimi-settings.edit', routeParams)">
                <Tab :value="DomainSettingsTab.BIMI">BIMI</Tab>
              </Link>
              <Link :href="route('ui.tls-settings.edit', routeParams)">
                <Tab :value="DomainSettingsTab.TLS">TLS-RPT</Tab>
              </Link>
            </TabList>
            <div>
              <slot />
            </div>
          </Tabs>
        </template>
      </Card>
      <div class="flex xl:w-1/3">
        <ScoreCard
          :score="cyclopsScore"
        >
          <template #note>
            {{ $t('scoring.note') }}
          </template>
        </ScoreCard>
      </div>
    </div>
    <slot name="footer" />
  </AppLayout>
</template>
