<script setup lang="ts">
import {
  type DnsDelegationVerificationResource, DnsDelegationVerificationType,
  type DomainResource, DomainType,
  type MtaStsSettingResource, type MtaStsSetupInstructionsResource,
  type TlsRptSettingResource, type TlsRptSetupInstructionsResource,
} from '@/types/types.gen';
import DomainSettingsLayout from '@/Pages/Domains/DomainSettingsLayout.vue';
import { DomainSettingsTab } from '@/Pages/Domains/types/DomainSettings';
import EnableMtaSettings from '@/Pages/Domains/TlsRptSettings/Partials/EnableMtaSettings.vue';
import EnableTlsRptSettings from '@/Pages/Domains/TlsRptSettings/Partials/EnableTlsRptSettings.vue';
import DisableMtaTlsSettings from '@/Pages/Domains/TlsRptSettings/Partials/DisableMtaTlsSettings.vue';
import TlsRptSettingsForm from '@/Pages/Domains/TlsRptSettings/Partials/TlsRptSettingsForm.vue';
import SaveAndExit from '@/Components/SaveAndExit.vue';
import { computed, provide, reactive } from 'vue';
import MtaStsRecordForm from '@/Pages/Domains/TlsRptSettings/Partials/MtaStsRecordForm.vue';
import axios from 'axios';
import { useToast } from 'primevue/usetoast';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { useRoute } from 'ziggy-js';
import MtaStsPolicyForm from '@/Pages/Domains/TlsRptSettings/Partials/MtaStsPolicyForm.vue';
import { router } from '@inertiajs/vue3';
import type { SaveOrExitAction } from '@/Pages/Domains/types';
import type { DomainScore } from '@/types/cyclops.types.gen';

const { domain, mtaStsSettings, tlsRptSettings, verifications } = defineProps<{
  domain: DomainResource;
  mtaStsSettings: MtaStsSettingResource;
  mtaStsSetupInstruction: MtaStsSetupInstructionsResource;
  tlsRptSettings: TlsRptSettingResource;
  tlsRptSetupInstruction: TlsRptSetupInstructionsResource;
  cyclopsScore: DomainScore | null;
  verifications: Partial<Record<DnsDelegationVerificationType, DnsDelegationVerificationResource>>;
}>();

const verificationsCollection = computed((): {
  tlsRpt: DnsDelegationVerificationResource;
  mtaStsRecord: DnsDelegationVerificationResource;
  mtaStsPolicy: DnsDelegationVerificationResource;
} => ({
  tlsRpt: Object.values(verifications).find(v => v.type === DnsDelegationVerificationType.TLSRPT)!,
  mtaStsRecord: Object.values(verifications).find(v => v.type === DnsDelegationVerificationType.MTA_STS)!,
  mtaStsPolicy: Object.values(verifications).find(v => v.type === DnsDelegationVerificationType.MTA_STS_POLICY)!,
}));

const formState = reactive({
  mtaStsSettings,
  tlsRptSettings,
});

provide('formState', formState);

const { translate } = useTranslate();
const toast = useToast();
const route = useRoute();

const submit = async (action: SaveOrExitAction) => {
  try {
    await Promise.all([
      axios.put(route('ui.mta-settings.update', {
        customer: domain.customer_uuid,
        domain: domain.uuid,
      }), formState.mtaStsSettings),

      axios.put(route('ui.tls-settings.update', {
        customer: domain.customer_uuid,
        domain: domain.uuid,
      }), formState.tlsRptSettings),
    ]);

    toast.add({ severity: 'success', summary: translate('global.form.success.title'), life: 5000 });

    if (action === 'save_exit') {
      router.visit(useRoute()('ui.domains.index', [domain.customer_uuid]));
    }
  } catch (error) {
    console.error(error);
    toast.add({
      severity: 'error',
      summary: translate('global.form.failed.title'),
      detail: translate('global.form.failed.description'),
      life: 5000,
    })
  }
};
</script>

<template>
  <DomainSettingsLayout
    :title="$t('tls_settings.title')"
    :domain="domain"
    :tab="DomainSettingsTab.TLS"
    :cyclops-score="cyclopsScore"
  >
    <div class="flex flex-col gap-8">
      <div class="flex flex-col gap-2">
        <TlsRptSettingsForm
          v-if="tlsRptSettings.enable_tls_rpt"
          :tls-rpt-setup-instruction="tlsRptSetupInstruction"
          :domain="domain"
          :verification="verificationsCollection.tlsRpt"
        />
        <EnableTlsRptSettings
          v-else
          :domain="domain"
        />
      </div>
      <div class="flex flex-col gap-2">
        <MtaStsPolicyForm
          v-if="mtaStsSettings.is_record_enabled"
          :mta-sts-setup-instruction="mtaStsSetupInstruction"
          :domain="domain"
          :verification="verificationsCollection.mtaStsPolicy"
        />
        <MtaStsRecordForm
          v-if="mtaStsSettings.is_record_enabled"
          :mta-sts-setup-instruction="mtaStsSetupInstruction"
          :domain="domain"
          :verification="verificationsCollection.mtaStsRecord"
        />
        <EnableMtaSettings
          v-if="!mtaStsSettings.is_record_enabled"
          :domain="domain"
        />
      </div>

      <DisableMtaTlsSettings
        v-if="tlsRptSettings.enable_tls_rpt || mtaStsSettings.is_record_enabled"
        :domain="domain"
        :tls-rpt-settings="tlsRptSettings"
        :mta-sts-settings="mtaStsSettings"
      />
    </div>

    <div v-if="!tlsRptSettings.enable_tls_rpt && !mtaStsSettings.is_record_enabled"  class="mt-8">
        <div
          class="text-lg font-bold"
          v-text="$t('mta_settings.info.heading')"
        />
        <div
          class="mt-2"
          v-text="$t('mta_settings.info.message')"
        />
    </div>

    <template #footer>
      <SaveAndExit
        :cancel-route="route('ui.domains.index', [domain.customer_uuid])"
        @submit="submit"
        :disabled="domain.type === DomainType.PARKED"
      />
    </template>
  </DomainSettingsLayout>
</template>
