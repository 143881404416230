<script setup lang="ts">
import {
  type DmarcSettingsResource,
  type DomainResource,
  type SenderIpAddressStatisticsResource,
  type SenderReportCategory,
  type SenderResource,
  type TimelineStatisticsResource,
} from '@/types/types.gen';
import { SenderCategory } from '@/types/types.gen';
import type { PaginatedResponse } from '@/types/http';
import AppLayout from '@/Layouts/AppLayout.vue';
import DatePicker, { type DatePickerEmitsOptions } from 'primevue/datepicker';
import Select from 'primevue/select';
import { provide, reactive } from 'vue';
import { Link, router, usePage } from '@inertiajs/vue3';
import { useRoute } from 'ziggy-js';
import type { DateRange, GroupedStatistics } from './types';
import { format } from 'date-fns';
import SenderReportsMenuBar from '@/Pages/Statistics/Sender/Partials/Menubars/SenderReportsMenuBar.vue';
import SenderCategoryMenubar from '@/Pages/Statistics/Sender/Partials/Menubars/SenderCategoryMenubar.vue';
import ReportRouter from '@/Pages/Statistics/Sender/Partials/Reports/ReportRouter.vue';
import IpStatisticsDrawer from '@/Pages/Statistics/IpAddress/IpStatisticsDrawer.vue';
import WarningCard from '@/Components/WarningCard.vue';
import Button from 'primevue/button';
import { makeStatsIdentity } from '@/Pages/Statistics/Sender/Helpers/DataAggregators';

const { activeCustomer } = usePage().props;
const route = useRoute();

const props = defineProps<{
  dateRange: DateRange;
  senders: SenderResource[];
  domains: PaginatedResponse<DomainResource>;
  domain: DomainResource;
  dmarcSettings: DmarcSettingsResource;
  aggregatedTimelineStatistics: SenderIpAddressStatisticsResource[];
  timelineStatistics: TimelineStatisticsResource[];
  reportCategory: SenderReportCategory;
  senderCategory?: SenderCategory;
}>();

const state = reactive<{
  category: SenderCategory;
  aggregatedTimelineStatistics: SenderIpAddressStatisticsResource[];
  dateRange: [Date, Date];
  activeDomain: string;
}>({
  aggregatedTimelineStatistics: props.aggregatedTimelineStatistics,
  category: props.senderCategory ?? SenderCategory.ALL,
  dateRange: [new Date(`${(props.dateRange.start_date)}`), new Date(`${(props.dateRange.end_date)}`)],
  activeDomain: props.domain.uuid,
});

provide('domain', props.domain);
provide('dmarcSettings', props.dmarcSettings);
provide('dateRage', state.dateRange);

const changeParams = (params: {
  domain?: string;
  customer?: string;
  start_date?: string;
  end_date?: string;
  reportCategory?: SenderReportCategory;
  senderCategory?: SenderCategory;
} = {}) => {
  router.get(route('ui.senders-statistics.index', {
    domain: params.domain ?? state.activeDomain,
    customer: params.customer ?? activeCustomer.uuid,
    start_date: params.start_date ?? format(state.dateRange[0], 'yyyy-LL-dd'),
    end_date: params.end_date ?? format(state.dateRange[1], 'yyyy-LL-dd'),
    report_category: params.reportCategory ?? props.reportCategory,
    sender_category: params.senderCategory ?? state.category,
  }));
};

const updateCategory = (category: SenderCategory) => {
  // Unfortunately the DB needs to filter the timeline statistics
  changeParams({ senderCategory: category });
  state.category = category;
  state.aggregatedTimelineStatistics = state.aggregatedTimelineStatistics.filter(
    statistic => category === SenderCategory.ALL || statistic.category === category,
  );
};

const changeCategory = (data: GroupedStatistics, senderCategory: SenderCategory) => {
  state.aggregatedTimelineStatistics.filter(stat => makeStatsIdentity(stat) === data.sender_identity)
    .forEach(statistic => statistic.category = senderCategory);
};

const updateSenderReportCategory = (reportCategory: SenderReportCategory) => {
  changeParams({ reportCategory });
};

const updateDomain = (domainUuid: string) => {
  changeParams({ domain: domainUuid });
};

const updateDateRange: DatePickerEmitsOptions['update:modelValue'] = (dateRange) => {
  // Will only have 2 dates after full range is selected
  if (!Array.isArray(dateRange) || !dateRange[0] || !dateRange[1]) {
    return;
  }

  changeParams({
    start_date: format(dateRange[0], 'yyyy-LL-dd'),
    end_date: format(dateRange[1], 'yyyy-LL-dd'),
  });
};
</script>

<template>
  <AppLayout :title="$t('senders_reports.title', { domain: props.domain.domain_name })">

    <div class="text-2xl font-semibold mb-8">
      {{ $t('senders_reports.title', { domain: props.domain.domain_name }) }}
    </div>

    <div class="flex flex-col gap-8">
      <SenderReportsMenuBar
        @menu-item-changed="updateSenderReportCategory"
        :current-category="props.reportCategory"
      />
      <div class="flex flex-col xl:flex-row gap-5">
        <Select
          filter
          v-model="state.activeDomain"
          optionValue="uuid"
          optionLabel="domain_name"
          :options="domains?.data ?? []"
          @update:modelValue="updateDomain($event)"
        />
        <DatePicker
          selectionMode="range"
          showIcon
          iconDisplay="input"
          v-model="state.dateRange"
          :maxDate="new Date()"
          hideOnRangeSelection
          @update:modelValue="updateDateRange($event)"
          pt:inputIconContainer:class="!-mt-3"
          dateFormat="yy-mm-dd"
          :fluid=true
          class="w-full xl:w-80"
        />
        <SenderCategoryMenubar
          @menu-item-changed="updateCategory"
          :current-category="state.category"
        />
      </div>

      <WarningCard
        v-if="!domain.first_report_date"
        :title="$t('reports.cards.domain_not_verified.header')"
        :description="$t('reports.cards.domain_not_verified.description')"
        class="w-full mt-8">
        <Link
          class="mt-2"
          :href="route('ui.dmarc-settings.edit', [domain.customer_uuid, domain.uuid])"
        >
          <Button
            :label="$t('reports.cards.domain_not_verified.actions.configure')"
            size="small"
          />
        </Link>
      </WarningCard>

      <WarningCard
        v-else-if="!timelineStatistics.length"
        class="w-full"
        :title="$t('reports.cards.no_reporting_data.header')"
        :description="$t('reports.cards.no_reporting_data.description')"
      />

      <ReportRouter
        v-else
        :sender-category="state.category"
        :reportCategory="props.reportCategory"
        :senders="senders"
        :aggregated-timeline-statistics="aggregatedTimelineStatistics"
        :timeline-statistics="timelineStatistics"
        :domain="props.domain"
        :customer-uuid="activeCustomer.uuid"
        @change-category="changeCategory"
      />
    </div>

    <IpStatisticsDrawer
      :date-range="dateRange"
      :domain="domain"
    />
  </AppLayout>
</template>
