<script lang="ts" setup>
import type { AggregateReportResource, SenderIpAddressStatisticsResource } from '@/types/types.gen';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import CompliancePercentageBar from '@/Pages/Statistics/Sender/Charts/CompliancePercentageBar.vue';
import { FilterMatchMode } from '@primevue/core/api';
import { useFormatters } from '@/Utils/Formatting';
import ShowMore from '@/Components/ShowMore.vue';
import ComplianceLegend from '@/Pages/Statistics/Sender/Partials/ComplianceLegend.vue';
import { computed, reactive } from 'vue';
import {
  groupAggregateReports,
  isFailing,
  isForward,
  isPassing,
} from '@/Pages/Statistics/Sender/Helpers/AggregateReportHelpers';
import type { Reporter } from '@/Pages/Statistics/IpAddress/types';
import Card from 'primevue/card';

const { aggregateReports, senderIpAddress } = defineProps<{
  aggregateReports: AggregateReportResource[];
  senderIpAddress: SenderIpAddressStatisticsResource;
}>();

const state = reactive({
  filters: {
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
  },
});

const { formatNumber, formatPercentage } = useFormatters();

let total = 0;

const hasExpandedData = (data: Reporter) => data.envelope_to.size > 0 || data.envelope_from.size > 0 || data.envelope_from.size > 0;

const getReporter = () =>
  Object.values(groupAggregateReports(aggregateReports)).reduce((acc, report) => {
    const orgKey = report.organisation;

    acc[orgKey] ||= {
      expanded: false,
      organisation: report.organisation,
      total_incidents: 0,
      total_passing: 0,
      total_forwards: 0,
      total_failing: 0,
      envelope_to: new Set(),
      envelope_from: new Set(),
      header_from: new Set(),
    };

    const reporter = acc[orgKey];

    for (const recordKey in report.records) {
      const record = report.records[recordKey];
      const recordCount = record.count;

      const isForwardRecord = isForward(record, senderIpAddress);
      const isPassingRecord = isPassing(record, senderIpAddress);
      const isFailingRecord = isFailing(record);
      total += record.count;

      reporter.total_incidents += recordCount;
      reporter.total_passing += isPassingRecord ? recordCount : 0;
      reporter.total_forwards += isForwardRecord ? recordCount : 0;
      reporter.total_failing += isFailingRecord ? recordCount : 0;

      if (record.identifiers_envelope_to) reporter.envelope_to.add(record.identifiers_envelope_to);
      if (record.identifiers_envelope_from) reporter.envelope_from.add(record.identifiers_envelope_from);
      if (record.identifiers_header_from) reporter.header_from.add(record.identifiers_header_from);
    }

    return acc;
  }, {} as Record<string, Reporter>);

const reporters = computed(getReporter);
</script>

<template>
  <Card class="w-full">
    <template #content>
      <DataTable
        v-model:filters="state.filters"
        :value="Object.values(reporters)"
        data-key="rid"
        paginator
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
        :currentPageReportTemplate="'&nbsp ' + $t('global.pagination.showing') + ' {first} ' + $t('global.pagination.to') + ' {last} ' + $t('global.pagination.of') + ' {totalRecords}'"
        :expanded-rows="Object.values(reporters)"
        @row-expand="(reporter) => reporter.data.expanded = true"
        @row-collapse="(reporter) => reporter.data.expanded = false"
        :global-filter-fields="['organisation']"
        :sort-order="-1"
        sort-field="total_incidents"
        :rows="10"
        :rowsPerPageOptions="[10, 20, 50]"
      >
        <template #empty>
          {{ $t('tables.not_found') }}
        </template>
        <template #header>
          <div class="flex items-center justify-between">
            <div class="flex flex-grow">
              <IconField>
                <InputIcon>
                  <i class="pi pi-search" />
                </InputIcon>
                <InputText
                  :placeholder="$t('tables.search')"
                  v-model="state.filters.global.value"
                />
              </IconField>
            </div>
            <div class="flex gap-6 text-sm bg-section-default px-4 py-3 rounded">
              <ComplianceLegend />
            </div>
          </div>

        </template>
        <Column expander class="w-12" />
        <Column
          :sortable="true"
          field="organisation"
          :header="$t('ip_address_modal.reporters.table.header.reporter')"
        />
        <Column
          :sortable="true"
          field="total_incidents"
          :header="$t('ip_address_modal.reporters.table.header.volume')"
        >
          <template #body="{ data }">
            {{ formatNumber(data.total_incidents) }}
          </template>
        </Column>

        <Column
          :sortable="true"
          field="percentage"
          :header="$t('ip_address_modal.reporters.table.header.percent_of_total')"
        >
          <template #body="{data}">
            {{ total ? formatPercentage(data.total_incidents / total * 100) : null }}
          </template>
        </Column>

        <Column
          :header="$t('ip_address_modal.reporters.table.header.compliance')"
        >
          <template #body="{ data }">
            <CompliancePercentageBar
              :data="data"
            />
          </template>
        </Column>

        <template #expansion="{data}">
          <div v-if="hasExpandedData(data)" class="bg-section-default mx-16 my-6 flex flex-col gap-3">
            <div v-if='data.envelope_to.size > 0' class="flex items-center gap-4">
              <div class=" flex shrink-0 font-semibold w-1/12"
                   v-text="$t('ip_address_modal.reporters.table.header.envelope_to')"
              />
              <ShowMore :set="data.envelope_to" :initial-count="10" />
            </div>
            <div v-if='data.envelope_from.size > 0' class="flex items-center gap-4">
              <div class="flex shrink-0 font-semibold w-1/12"
                   v-text="$t('ip_address_modal.reporters.table.header.envelope_from')"
              />
              <ShowMore :set="data.envelope_from" :initial-count="10" />
            </div>
            <div v-if='data.header_from.size > 0' class="flex items-center gap-4">
              <div
                class="flex shrink-0 font-semibold w-1/12"
                v-text="$t('ip_address_modal.reporters.table.header.header_from')"
              />
              <ShowMore :set="data.header_from" :initial-count="10" />
            </div>
          </div>
          <div v-else class="bg-section-default mx-16 my-6 flex flex-col gap-4">
            {{ $t('ip_address_modal.reporters.table.header.no_data') }}
          </div>
        </template>
      </DataTable>
    </template>
  </Card>
</template>
