<script setup lang="ts">
import type { DomainResource, FailureReportResource } from '@/types/types.gen';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import { Link, router } from '@inertiajs/vue3';
import Button from 'primevue/button';
import Card from 'primevue/card';
import { FilterMatchMode } from '@primevue/core/api';

import { useRoute } from 'ziggy-js';
import { useFormatters } from '@/Utils/Formatting';
import { reactive } from 'vue';
import IconField from 'primevue/iconfield';
import Select from 'primevue/select';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import DeliveryResultTag from '@/Pages/Statistics/FailureReports/Partials/DeliveryResultTag.vue';
import { defaultTo } from 'lodash';
const { formatDate } = useFormatters();

const { domain } = defineProps<{
  reports: FailureReportResource[];
  domains: DomainResource[];
  domain: DomainResource;
  report?: FailureReportResource;
}>();

const getShowRoute = (report: FailureReportResource) => {
  return useRoute()('ui.failure-reports.show', {
    customer: domain.customer_uuid,
    domain: domain.uuid,
    failure_report: report.uuid,
  })
}

const changeDomain = (domainUuid: string) => {
  router.get(useRoute()('ui.failure-reports.index', [
    domain.customer_uuid,
    domainUuid,
  ]));
}

const state = reactive({
  activeDomain: domain.uuid,
  filters: {
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
  },
});

</script>

<template>
  <Card class="w-full">
    <template #content>
      <DataTable
        v-model:filters="state.filters"
        :value="reports"
        :global-filter-fields="['original_mail_from']"
        data-key="uuid"
        paginator
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
        :currentPageReportTemplate="'&nbsp ' + $t('global.pagination.showing') + ' {first} ' + $t('global.pagination.to') + ' {last} ' + $t('global.pagination.of') + ' {totalRecords}'"
        :rows="10"
        :rowsPerPageOptions="[10, 20, 50]"
        :sort-order=1
        sort-field="arrival_date"
        :default-sort-order="1"
      >
        <template #empty>
          {{ $t('tables.not_found') }}
        </template>

        <template #header>
          <div class="flex gap-4">
            <Select
              filter
              v-model="state.activeDomain"
              optionValue="uuid"
              optionLabel="domain_name"
              :options="domains ?? []"
              @update:modelValue="changeDomain($event)"
            />
            <IconField>
              <InputIcon>
                <i class="pi pi-search" />
              </InputIcon>
              <InputText
                :placeholder="$t('tables.search')"
                v-model="state.filters.global.value"
              />
            </IconField>
          </div>
        </template>

        <Column
          field="arrival_date"
          :header="$t('failure_reports.table.header.date')"
          :sortable="true"
        >
          <template #body="{ data }: {data: FailureReportResource}">
            {{ formatDate(new Date(data.arrival_date)) }}
          </template>
        </Column>

        <Column
          field="original_mail_from"
          :header="$t('failure_reports.table.header.return_path')"
        >
          <template #body="{ data }: {data: FailureReportResource}">
            {{ defaultTo(data.original_mail_from.replace(/[<>]/g, ''), '-') }}
          </template>
        </Column>

        <Column
          field="source_ip"
          :header="$t('failure_reports.table.header.ip_address')"
          :sortable="true"
        >
          <template #body="{ data }: {data: FailureReportResource}">
            {{ data.source_ip }}
          </template>
        </Column>

        <Column
          field="auth_failure"
          :header="$t('failure_reports.table.header.authentication')"
          :sortable="true"
        >
          <template #body="{ data }: {data: FailureReportResource}">
            {{ data.auth_failure }}
          </template>
        </Column>

        <Column
          field="delivery_result"
          :header="$t('failure_reports.table.header.result')"
          :sortable="true"
        >
          <template #body="{ data }: {data: FailureReportResource}">
            <DeliveryResultTag :result="data.delivery_result" />
          </template>
        </Column>

        <Column>
          <template #body="{ data }: {data: FailureReportResource}">
            <div class="flex flex-row-reverse">
              <Link :href="getShowRoute(data)" preserve-state>
                <Button
                  text
                  icon="pi pi-angle-right"
                />
              </Link>
            </div>
          </template>
        </Column>
      </DataTable>
    </template>
  </Card>
</template>
