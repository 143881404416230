<script setup lang="ts">
import { ref, computed } from 'vue';

const { content, maxVisibleLength } = defineProps<{
  content: string;
  maxVisibleLength: number;
}>();

const isShowingMore = ref(false);

const displayedContent = computed(() => {
  return isShowingMore.value || content.length <= maxVisibleLength
    ? content
    : content.slice(0, maxVisibleLength) + '...';
});

const toggleShowMore = () => {
  isShowingMore.value = !isShowingMore.value;
};
</script>

<template>
<p>
  {{ displayedContent }}
  <span
    v-if="content.length > maxVisibleLength && !isShowingMore"
    @click="toggleShowMore"
    class="text-primary-default cursor-pointer"
  >
    {{ $t('global.read_more') }}
  </span>
</p>
</template>
